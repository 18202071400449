<template>
    <path id="Vector_43" d="M824 313.74L861.37 239H961.114L998.484 313.74L961.114 388.48H861.37L824 313.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_22" fill-rule="evenodd" clip-rule="evenodd"
        d="M857.87 234H963.794L1003.66 313.74L963.794 393.48H857.87L818 313.74L857.87 234ZM864.05 244L829.18 313.74L864.05 383.48H957.614L992.484 313.74L957.614 244H864.05Z"
        fill="#D4A168" />
    <path id="Vector_44" d="M824 154.74L861.37 80H961.114L998.484 154.74L961.114 229.48H861.37L824 154.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_23" fill-rule="evenodd" clip-rule="evenodd"
        d="M857.87 75H963.794L1003.66 154.74L963.794 234.48H857.87L818 154.74L857.87 75ZM864.05 85L829.18 154.74L864.05 224.48H957.614L992.484 154.74L957.614 85H864.05Z"
        fill="#D4A168" />
    <path id="Vector_45" d="M824 472.74L861.37 398H961.114L998.484 472.74L961.114 547.48H861.37L824 472.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_24" fill-rule="evenodd" clip-rule="evenodd"
        d="M857.87 393H963.794L1003.66 472.74L963.794 552.48H857.87L818 472.74L857.87 393ZM864.05 403L829.18 472.74L864.05 542.48H957.614L992.484 472.74L957.614 403H864.05Z"
        fill="#D4A168" />
</template>