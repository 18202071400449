<template>
    <path id="Vector_19" d="M411 81.74L448.37 7H548.114L585.484 81.74L548.114 156.48H448.37L411 81.74Z" fill="#D4A168" />
    <path id="Vector (Stroke)_10" fill-rule="evenodd" clip-rule="evenodd"
        d="M444.87 2H550.794L590.664 81.74L550.794 161.48H444.87L405 81.74L444.87 2ZM451.05 12L416.18 81.74L451.05 151.48H544.614L579.484 81.74L544.614 12H451.05Z"
        fill="#D4A168" />
    <path id="Vector_20" d="M411 238.74L448.37 164H548.114L585.484 238.74L548.114 313.48H448.37L411 238.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_11" fill-rule="evenodd" clip-rule="evenodd"
        d="M444.87 159H550.794L590.664 238.74L550.794 318.48H444.87L405 238.74L444.87 159ZM451.05 169L416.18 238.74L451.05 308.48H544.614L579.484 238.74L544.614 169H451.05Z"
        fill="#D4A168" />
    <path id="Vector_21" d="M411 395.74L448.37 321H548.114L585.484 395.74L548.114 470.48H448.37L411 395.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_12" fill-rule="evenodd" clip-rule="evenodd"
        d="M444.87 316H550.794L590.664 395.74L550.794 475.48H444.87L405 395.74L444.87 316ZM451.05 326L416.18 395.74L451.05 465.48H544.614L579.484 395.74L544.614 326H451.05Z"
        fill="#D4A168" />
</template>