<template>
    <path id="Vector_7" d="M141 80.74L178.37 6H278.114L315.484 80.74L278.114 155.48H178.37L141 80.74Z" fill="#D4A168" />
    <path id="Vector (Stroke)_4" fill-rule="evenodd" clip-rule="evenodd"
        d="M174.87 1H280.794L320.664 80.74L280.794 160.48H174.87L135 80.74L174.87 1ZM181.05 11L146.18 80.74L181.05 150.48H274.614L309.484 80.74L274.614 11H181.05Z"
        fill="#D4A168" />
    <path id="Vector_8" d="M141 239.74L178.37 165H278.114L315.484 239.74L278.114 314.48H178.37L141 239.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_5" fill-rule="evenodd" clip-rule="evenodd"
        d="M174.87 160H280.794L320.664 239.74L280.794 319.48H174.87L135 239.74L174.87 160ZM181.05 170L146.18 239.74L181.05 309.48H274.614L309.484 239.74L274.614 170H181.05Z"
        fill="#D4A168" />
    <path id="Vector_9" d="M141 396.74L178.37 322H278.114L315.484 396.74L278.114 471.48H178.37L141 396.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_6" fill-rule="evenodd" clip-rule="evenodd"
        d="M174.87 317H280.794L320.664 396.74L280.794 476.48H174.87L135 396.74L174.87 317ZM181.05 327L146.18 396.74L181.05 466.48H274.614L309.484 396.74L274.614 327H181.05Z"
        fill="#D4A168" />
</template>