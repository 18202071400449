<template>
    <path id="Vector_49" d="M1099 156.74L1136.37 82H1236.11L1273.48 156.74L1236.11 231.48H1136.37L1099 156.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_25" fill-rule="evenodd" clip-rule="evenodd"
        d="M1132.87 77H1238.79L1278.66 156.74L1238.79 236.48H1132.87L1093 156.74L1132.87 77ZM1139.05 87L1104.18 156.74L1139.05 226.48H1232.61L1267.48 156.74L1232.61 87H1139.05Z"
        fill="#D4A168" />
    <path id="Vector_50" d="M1099 313.74L1136.37 239H1236.11L1273.48 313.74L1236.11 388.48H1136.37L1099 313.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_26" fill-rule="evenodd" clip-rule="evenodd"
        d="M1132.87 234H1238.79L1278.66 313.74L1238.79 393.48H1132.87L1093 313.74L1132.87 234ZM1139.05 244L1104.18 313.74L1139.05 383.48H1232.61L1267.48 313.74L1232.61 244H1139.05Z"
        fill="#D4A168" />
    <path id="Vector_51" d="M1099 470.74L1136.37 396H1236.11L1273.48 470.74L1236.11 545.48H1136.37L1099 470.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_27" fill-rule="evenodd" clip-rule="evenodd"
        d="M1132.87 391H1238.79L1278.66 470.74L1238.79 550.48H1132.87L1093 470.74L1132.87 391ZM1139.05 401L1104.18 470.74L1139.05 540.48H1232.61L1267.48 470.74L1232.61 401H1139.05Z"
        fill="#D4A168" />
</template>