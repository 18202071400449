<template>
    <path id="Vector_37" d="M546 156.74L583.37 82H683.114L720.484 156.74L683.114 231.48H583.37L546 156.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_19" fill-rule="evenodd" clip-rule="evenodd"
        d="M579.87 77H685.794L725.664 156.74L685.794 236.48H579.87L540 156.74L579.87 77ZM586.05 87L551.18 156.74L586.05 226.48H679.614L714.484 156.74L679.614 87H586.05Z"
        fill="#D4A168" />
    <path id="Vector_38" d="M546 312.74L583.37 238H683.114L720.484 312.74L683.114 387.48H583.37L546 312.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_20" fill-rule="evenodd" clip-rule="evenodd"
        d="M579.87 233H685.794L725.664 312.74L685.794 392.48H579.87L540 312.74L579.87 233ZM586.05 243L551.18 312.74L586.05 382.48H679.614L714.484 312.74L679.614 243H586.05Z"
        fill="#D4A168" />
    <path id="Vector_39" d="M546 471.74L583.37 397H683.114L720.484 471.74L683.114 546.48H583.37L546 471.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_21" fill-rule="evenodd" clip-rule="evenodd"
        d="M579.87 392H685.794L725.664 471.74L685.794 551.48H579.87L540 471.74L579.87 392ZM586.05 402L551.18 471.74L586.05 541.48H679.614L714.484 471.74L679.614 402H586.05Z"
        fill="#D4A168" />
</template>