<template>
    <path id="Vector_31" d="M685 396.74L722.37 322H822.114L859.484 396.74L822.114 471.48H722.37L685 396.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_16" fill-rule="evenodd" clip-rule="evenodd"
        d="M718.87 317H824.794L864.664 396.74L824.794 476.48H718.87L679 396.74L718.87 317ZM725.05 327L690.18 396.74L725.05 466.48H818.614L853.484 396.74L818.614 327H725.05Z"
        fill="#D4A168" />
    <path id="Vector_32" d="M685 79.74L722.37 5H822.114L859.484 79.74L822.114 154.48H722.37L685 79.74Z" fill="#D4A168" />
    <path id="Vector (Stroke)_17" fill-rule="evenodd" clip-rule="evenodd"
        d="M718.87 0H824.794L864.664 79.74L824.794 159.48H718.87L679 79.74L718.87 0ZM725.05 10L690.18 79.74L725.05 149.48H818.614L853.484 79.74L818.614 10H725.05Z"
        fill="#D4A168" />
    <path id="Vector_33" d="M685 237.74L722.37 163H822.114L859.484 237.74L822.114 312.48H722.37L685 237.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_18" fill-rule="evenodd" clip-rule="evenodd"
        d="M718.87 158H824.794L864.664 237.74L824.794 317.48H718.87L679 237.74L718.87 158ZM725.05 168L690.18 237.74L725.05 307.48H818.614L853.484 237.74L818.614 168H725.05Z"
        fill="#D4A168" />
</template>