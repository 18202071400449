<template>
    <path id="Vector_25" d="M963 396.74L1000.37 322H1100.11L1137.48 396.74L1100.11 471.48H1000.37L963 396.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_13" fill-rule="evenodd" clip-rule="evenodd"
        d="M996.87 317H1102.79L1142.66 396.74L1102.79 476.48H996.87L957 396.74L996.87 317ZM1003.05 327L968.18 396.74L1003.05 466.48H1096.61L1131.48 396.74L1096.61 327H1003.05Z"
        fill="#D4A168" />
    <path id="Vector_26" d="M963 79.74L1000.37 5H1100.11L1137.48 79.74L1100.11 154.48H1000.37L963 79.74Z" fill="#D4A168" />
    <path id="Vector (Stroke)_14" fill-rule="evenodd" clip-rule="evenodd"
        d="M996.87 0H1102.79L1142.66 79.74L1102.79 159.48H996.87L957 79.74L996.87 0ZM1003.05 10L968.18 79.74L1003.05 149.48H1096.61L1131.48 79.74L1096.61 10H1003.05Z"
        fill="#D4A168" />
    <path id="Vector_27" d="M963 238.74L1000.37 164H1100.11L1137.48 238.74L1100.11 313.48H1000.37L963 238.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_15" fill-rule="evenodd" clip-rule="evenodd"
        d="M996.87 159H1102.79L1142.66 238.74L1102.79 318.48H996.87L957 238.74L996.87 159ZM1003.05 169L968.18 238.74L1003.05 308.48H1096.61L1131.48 238.74L1096.61 169H1003.05Z"
        fill="#D4A168" />
</template>