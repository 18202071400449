<template>
    <path id="Vector_13" d="M278 158.74L315.37 84H415.114L452.484 158.74L415.114 233.48H315.37L278 158.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_7" fill-rule="evenodd" clip-rule="evenodd"
        d="M311.87 79H417.794L457.664 158.74L417.794 238.48H311.87L272 158.74L311.87 79ZM318.05 89L283.18 158.74L318.05 228.48H411.614L446.484 158.74L411.614 89H318.05Z"
        fill="#D4A168" />
    <path id="Vector_14" d="M278 469.74L315.37 395H415.114L452.484 469.74L415.114 544.48H315.37L278 469.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_8" fill-rule="evenodd" clip-rule="evenodd"
        d="M311.87 390H417.794L457.664 469.74L417.794 549.48H311.87L272 469.74L311.87 390ZM318.05 400L283.18 469.74L318.05 539.48H411.614L446.484 469.74L411.614 400H318.05Z"
        fill="#D4A168" />
    <path id="Vector_15" d="M278 312.74L315.37 238H415.114L452.484 312.74L415.114 387.48H315.37L278 312.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_9" fill-rule="evenodd" clip-rule="evenodd"
        d="M311.87 233H417.794L457.664 312.74L417.794 392.48H311.87L272 312.74L311.87 233ZM318.05 243L283.18 312.74L318.05 382.48H411.614L446.484 312.74L411.614 243H318.05Z"
        fill="#D4A168" />
</template>